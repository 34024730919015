// theme.scss
@use '@angular/material' as mat;
@import '@angular/material/theming';

$primary-cust: (
    50 : #e5e9e9,
    100 : #bdc7c7,
    200 : #91a2a2,
    300 : #657d7d,
    400 : #446161,
    500 : #3C414F,
    600 : #1f3e3e,
    700 : #1a3636,
    800 : #152e2e,
    900 : #0c1f1f,
    A100 : #5fffff,
    A200 : #2cffff,
    A400 : #00f8f8,
    A700 : #00dfdf,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$secondary-cust: (
    50 : #e0f5f8,
    100 : #b3e7ee,
    200 : #80d7e3,
    300 : #4dc6d8,
    400 : #26bacf,
    500 : #00aec7,
    600 : #00a7c1,
    700 : #009dba,
    800 : #0094b3,
    900 : #0084a6,
    A100 : #d0f4ff,
    A200 : #9de8ff,
    A400 : #6adbff,
    A700 : #51d5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$corporate-medium: #48657B;
$corporate-dark: #244545;
$medium-gray: #323941;
$light-gray: #FAFAFA;
$white: #FFFFFF;
$success: #20B901;
$warning: orange;
$error: #CC0505;

$air-theme-primary: mat.define-palette($primary-cust);
$air-theme-accent : mat.define-palette($secondary-cust);
$air-theme-warn   : mat.define-palette(mat.$red-palette);

$air-theme: mat.define-light-theme(
    $air-theme-primary,
    $air-theme-accent,
    $air-theme-warn,
);

@function my-mat-light-theme-foreground($color) {
  @return (
    base:              $color,
    divider:           $dark-dividers,
    dividers:          $dark-dividers,
    disabled:          rgba($color, 0.38),
    disabled-button:   rgba($color, 0.26),
    disabled-text:     rgba($color, 0.38),
    elevation:         black,
    hint-text:         rgba($color, 0.38),
    secondary-text:    $color,
    icon:              rgba($color, 0.54),
    icons:             rgba($color, 0.54),
    text:              $color,
    slider-min:        rgba($color, 0.87),
    slider-off:        rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38),
  );
};
$foreground: my-mat-light-theme-foreground($corporate-medium);
$air-theme: map-merge($air-theme, (foreground: $foreground));
