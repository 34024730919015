
/* ===============
   COMMON STYLES
   =============== */
.btn-sidebar-toggle {
    border-radius: 2px;
    padding: 8px 12px;
    display: inline-block;
    opacity: 1;
}
.btn-sidebar-toggle .text {
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    display: inline-block;
}
.btn-sidebar-toggle .icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 12px;
}

/* Bottom Bar / Barra Inferior */
.bottom-bar {
    height: 4px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #3BCCDD;
    z-index: 2;
}


/* ===============
   HOME PAGE
   =============== */

/* Lead Text / Texto Destacado */
.lead-text {
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
    max-width: 440px;
    margin-bottom: 64px;
}
@media (max-width: 767.98px) {
    .lead-text {
        font-size: 16px;
        margin-bottom: 32px;
        margin-top: 2vh !important;
    }
}
